/*
 * This file contains hacks, work arounds, and fixes that are bad code.
 * Only add to this file if there is not a better way to do it.
 *
 * Be sure to document any additions to this file with a descriptive comment.
 * We'd like this file to be empty and good descriptions make it easier to refactor and remove css from here.
 */

// This hack only targets IE10 and IE11 in order to display the modern browser support message
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mc-modern-browser-support {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    font: 400 1.4rem/1.215 Roboto, 'Helvetica Neue', sans-serif;
    text-align: center;

    // Make it look like the message is in a card since the gradient background is used in this app
    max-width: 90rem;
    padding: 2.2rem;
    border-radius: $mat-card-border-radius;
    background: #fff;
  }
}
