// Make the html fill the viewport so that the page gradient fills the entire page
html {
  height: 100%;
}

// Since the page scrolls move the footer to the bottom of the content so it scrolls with the page
.mc-page-full-footer {
  position: static;
  margin: $page-spacing $spacing-none $spacing-none;
  max-width: 100vw;
  width: $full-page-card-width;
}

@media (max-width: $full-page-card-width-px) {
  .mc-admin-login-card,
  .mc-complete-invite-card,
  .mc-expired-password-card,
  .mc-forgot-password-card,
  .mc-license-list-card,
  .mc-license-url-card,
  .mc-login-card,
  .mc-reset-password-card,
  .mc-sso-login-card {
    .mat-card {
      max-width: 100vw;
      border-radius: $border-radius-none;
    }
  }
}
